:root {
  --color-dark: #13203d;
  --color-darkest: #0c1c2c;
  --color-dark-mid: #0d4c6b70;

  --color-light: #f4f5f6;
  --color-highlight: #5eedcf;
  --color-highlight-neon: #ed2af1;
  --color-highlight-base: #e0c705;

  --border-radius: 13px;
}

body {
  background-color: var(--color-darkest);  
}

p {
  text-shadow: 2px 2px 5px #030303;
}

/* Main */
.top-scene-container,
.bottom-scene-container  {
  background-color: rgba(0, 0, 0, 0.53);
  background-image: url("/public/images/bg-1.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-blend-mode: color;
}

main .about-col {
  margin-top: 10vh;  
}

main h1 {
  background-image: linear-gradient(72deg, var(--color-highlight-neon), var(--color-highlight-base));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

main h1 span {
  color: var(--color-highlight);
  display: block;
}

main p  {
  color: var(--color-light);
}

.icon-smaller {
  font-size: calc(1.5rem + 2vw);
}

.icon {
  font-size: calc(3.5rem + 4vw);
}

nav {
  background-color: #190503e3;
  box-shadow: 0px 0px 30px rgba(252, 215, 5, 0.245);  
}

#intro {
  box-shadow: 
  0px 100px 250px rgba(146, 11, 236, 0.478),
  0px 25px 30px rgba(236, 11, 52, 0.641);
}

#intro .icon-smaller {
  display: inline-block;
  position: relative;
  top: 14px;
}

#about {
    animation-name: glow;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    transition: 0.3s ease-in-out;
}

@keyframes glow {
  0%   { filter: drop-shadow(0 0 2.75rem rgb(244, 0, 175, 0.603)); }
  25%  { filter: drop-shadow(0 0 1.75rem rgba(22, 192, 67, 0.503)); } 
  75%  { filter: drop-shadow(0 0 1.75rem rgb(0, 163, 244, 0.403)); }
  100% { filter: drop-shadow(0 0 2.75rem rgb(244, 0, 175, 0.603)); }
}


#three-things {
  background-color: rgba(18, 4, 15, 0.489);
  box-shadow: inset 0px 50px 60px rgba(10, 9, 3, 0.117);
  text-align: center;
  color: var(--color-light);
  box-shadow: 
    0px 20px 140px rgba(16, 1, 26, 0.869),
    0px -20px 40px rgba(236, 11, 213, 0.828);
}


#three-things h3 {
  color: var(--color-highlight);
}


h2 {
  background-image: linear-gradient(72deg, var(--color-highlight-neon), var(--color-highlight-base));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  margin-top: 2.1rem;
  margin-bottom: 2.8rem;
}

h2 span {
  display: block;
  font-size: 60%;
  font-weight: 500;
}


#portfolio {
  cursor: pointer;
  box-shadow: 
    0px 50px 300px rgba(63, 13, 96, 0.151),
    0px 10px 10px rgba(236, 11, 52, 0.14);
}

#videos {
background-color: #0d1c2d;
}

.portfolio-panel {
  border:0;
  transition: filter 0.6s ease-in-out;
  background-color: var(--color-dark);
}

.portfolio-panel .card-img {
  border-radius: 0;
}

.portfolio-panel .content {
  padding: 20px;
}

.portfolio-panel figure { overflow: hidden; }

.portfolio-panel figure img {
  filter: brightness(0.64);
  transition: 
    transform 1.1s ease-in-out, 
    filter 0.6s ease-in-out;
}

.portfolio-panel figure img:hover {
  filter: brightness(1.0);
  transform: scale(1.3);
  transition: 
    transform 1.1s ease-in-out, 
    filter 0.6s ease-in-out;
}

.portfolio-panel button, 
.portfolio-panel .icon img {
  height: 35px;
}

.portfolio-panel .icon img  {
  display: inline-block;
  filter: brightness(0.6);
  aspect-ratio: 1 / 1;
  background-color: transparent;
  border: 0;
}

.portfolio-panel .tech {
  color: rgba(212, 217, 166, 0.644);
}

#portfolio .button-effect {
  color: rgba(183, 177, 177, 0.444);
  display: block;
  transform: scale(1.0);
  transition: 0.6s ease-in-out;
}

#portfolio button.button-effect {
  color: black;
}

#portfolio .button-effect:hover {
  color: rgba(255, 255, 255, 0.876);
  transform: scale(1.1);
  filter: drop-shadow(0 0 20px rgba(0, 5, 9, 0.919));
  z-index: 999;
}

/* Disable hover effects on mobile */
@media (max-width: 768px) {
  #portfolio .button-effect:hover
  {
      transform: scale(1.0);
      filter: none;
  }
}


#skills {
  background-color: rgba(1, 5, 8, 0.348);
  box-shadow: inset 0px 50px 40px rgba(1, 1, 0, 0.117);
}

#skills img { 
  width: 10%;
}



#social {
  box-shadow: 0px 0px 60px rgba(211, 182, 17, 0.117);  
  max-width: 750px;
}

#social img {
  width: clamp(70px, 4vw, 90px);
  display: "inline-block";
  filter: invert(0);
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);

}

.border-style {
  border: 7px solid var(--color-dark-mid);
  border-radius: var(--border-radius);
}

.button-style {
  color: #000;
  font-weight: 700;
  background-image: linear-gradient(72deg, var(--color-highlight-neon), var(--color-highlight-base));
  border: 3px solid var(--color-highlight-neon);
}

.button-effect {
  display: block;
  transform: scale(1.0);
  transition: transform 0.23s ease-in-out;
}

.button-effect:hover {
  transform: scale(1.13);
  filter: drop-shadow(0 0 20px rgba(0, 5, 9, 0.919));
  z-index: 999;
}

/* Disable hover effects on mobile */
@media (max-width: 768px) {
  .button-effect:hover
  {
      transform: scale(1.0);
      filter: none;
  }
}

.easter-egg-1  {
  cursor: url("/public/cursor/bulbasaur.cur"), auto;
}

.easter-egg-2  {
  cursor: url("/public/cursor/blastoise.cur"), auto;
}

.easter-egg-3  {
  cursor: url("/public/cursor/volcarona.cur"), auto;
}


/* 1/4 => Needed for Pan360 */
.media-container {
  position: relative;  
}

/* 2/4 => Needed for Pan360 */
.media-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* custom cursor Pan360 */
.pnlm-grab {
  cursor: url("/public/cursor/mewtwo.cur"), auto;
}

.media-content {
  margin-top: 20%;  /* 3/4 */
  margin-bottom: 10%; /* 4/4 => Needed for Pan360 */

  background-color: rgba(20, 17, 28, 0.806);  
  padding: 30px;
  box-shadow: 5px 5px 10px rgba(248, 125, 255, 0.738), -5px -5px 10px rgba(220, 248, 37, 0.31);
}

.media-content-column {
  z-index: 10;
  opacity: 1;
  transition: opacity 1.9s ease-in-out;
}

.media-content-column-hide {
  z-index: -999;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}


footer .icon-smaller {
  position: relative;  

  animation-name: slideY;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transition: 0.3s ease-in-out;
}



@keyframes slideY {
  0%   { top: -10%; 
    filter: drop-shadow(0 0 4.75rem rgb(244, 0, 0));
  }

  50%  { top: 10%; 
    filter: drop-shadow(0 0 1.75rem rgb(42, 5, 5));    
  } 

  100% { top: -10%; 
    filter: drop-shadow(0 0 4.75rem rgb(244, 0, 0));
  }
}
