@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

html { scroll-behavior: smooth; }
/* body {
  transition-duration: 1.3s;
  transition-timing-function: cubic-bezier(.97,.15,.82,.75);
  transition-delay: 0.15s;
} */

* { font-family: 'Mulish', sans-serif; }

h1 {
  font-size: clamp(2.0rem, 3vw, 6.0rem);
  font-weight: 800;
}

h2 {
  font-size: clamp(2.5rem, 4vw, 3.2rem);
  font-weight: 700;
}

h3 {
  font-size: clamp(1.8em, 4vw, 2.0rem);
  font-weight: 800;
}

main h1 span {
  font-size: clamp(1.6rem, 5vw, 1.9rem);
  font-weight: 300;
}

main p {
  font-size: clamp(1.2rem, 2vw, 1.6rem);
}

a { text-decoration: none; }


.three-things-container p {
  font-size: clamp(1.2rem, 4vw, 1.45rem);
}

li, p {
  font-size: clamp(1.0rem, 4vw, 1.25rem);
}


